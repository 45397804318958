<template>
  <div>
    <!-- <div class="person_infor">
			<div class="person_infor_left">
				<div class="login_top">
					<div class="img"> <img src="@/assets/login/flag.png"></div>
					<div class="login_title" @click="backClick">{{$t('set.adviceFeedback')}}</div>
					<div class="front_img">
						<img src="../../../assets/home_icons/front.png">
					</div>
					<div class="sub_title">{{$t('title.feedbackRecord')}}</div>
				</div>
			</div>
		</div>

		<div class="record" v-if="feedBackList.length>0" v-for="(item,index) in feedBackList" :key="index">
			<div class="record_top">
				<div class="record_top_left">
					<img :src="item.user_head">
				</div>
				<div class="record_top_right">
					{{item.body}}
				</div>
			</div>
			<div class="record_body">
				<div class="record_img">
					<div class="record_img_item" v-for="(item2,index2) in item.thumb" :index="index2">
						<img :src="item2">
					</div>
				</div>
				<div class="record_type">
					{{$t('set.feedbackType')}}：{{item.type}}
				</div>
				<div class="record_type">
					{{$t('set.feedbackTime')}}：{{item.create_time}}
				</div>
				<div class="reply" v-if="item.replay">
					{{$t('set.platformReply')}}：{{item.replay}}
				</div>
			</div>
		</div>
		<div class="empty" v-if="feedBackList.length==0">
			<el-empty :image-size=" 200" :description="$t('noData')">
			</el-empty>
    </div>-->

    <div class="top_box">
      <div class="login_title" @click="goback">{{ $t("set.adviceFeedback") }}</div>
      <img src="../../../assets/mycenter/jiantou.png" alt />
      <div class="login_title_1">{{ $t("set.Historical_feedback") }}</div>
    </div>

    <div class="table_box">
      <el-table :data="feedBackList" border style="width: 100%">
        <el-table-column type="index" label="ID" width="88" align="center" class-name="table_id"></el-table-column>
        <el-table-column :label="$t('set.feedbackType')" width="180" align="center">
          <template slot-scope="scope">
            <div>{{lang == 'zh'?scope.row.type:scope.row.type_bnm }}</div>
            <!-- <div v-else>{{ scope.row.type_bnm }}</div> -->
          </template>
        </el-table-column>
        <el-table-column :label="$t('order.photo')" align="center">
          <template slot-scope="scope">
            <img
              :src="item"
              alt
              style="width: 150px; height: 150px"
              v-for="(item, index) in scope.row.thumb"
              :key="index"
            />
          </template>
        </el-table-column>
        <el-table-column prop="replay" :label="$t('set.platformReply')" align="center"></el-table-column>
        <el-table-column prop="create_time" :label="$t('set.feedbackTime')" align="center"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      feedBackList: [],
      tableData: [
        {
          date: "1",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄"
        },
        {
          date: "2",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄"
        },
        {
          date: "3",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄"
        },
        {
          date: "4",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄"
        }
      ],
      lang: "sp"
    };
  },
  mounted() {
    this.getSuggestions();

    // 监听localstorage里面的数据
    window.addEventListener("setItemEvent", e => {
      if (e.key === "lang") {
        this.lang = e.newValue;
      }
    });
  },
  methods: {
    backClick() {
      this.$router.back(-1);
    },
    getSuggestions() {
      this.$http.suggestionsList().then(res => {
        console.log(res);
        if (res.code == 1) {
          this.feedBackList = res.data;
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    goback() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped="" lang="less">
.top_box {
  height: 72px;
  display: flex;
  justify-content: left;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 40px;

  .table_id {
    color: "#ECDFCC";
  }

  .login_title {
    width: 72px;
    height: 24px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 24px;
    color: #f39800;
    opacity: 1;
  }

  img {
    width: 14px;
    height: 14px;
    margin: 0 10px;
  }

  .login_title_1 {
    white-space: nowrap;
    width: 72px;
    height: 24px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
    opacity: 1;
  }
}

.table_box {
  padding: 32px 40px;
}

// .person_infor {
// 	box-sizing: border-box;
// 	width: 926px;
// 	display: flex;
// 	justify-content: space-between;
// 	padding: 24px;
// 	border-bottom: 1px solid #F3F5F6;
// }

// .person_infor_left {
// 	.login_top {
// 		display: flex;
// 		align-items: center;
// 		font-size: 16px;

// 		.img {
// 			display: flex;
// 			align-items: center;

// 			img {
// 				width: 18px;
// 				height: 18px;
// 			}
// 		}

// 		.login_title {
// 			margin-left: 8px;

// 			color: #666666;

// 			&:hover {
// 				cursor: pointer;
// 			}
// 		}

// 		.front_img {
// 			display: flex;
// 			align-items: center;
// 			margin: 0 12px;

// 			img {
// 				width: 8px;
// 				height: 14px;
// 			}
// 		}

// 		.title_bold {
// 			font-weight: bold;
// 		}

// 		.sub_title {
// 			color: #333333;
// 		}
// 	}
// }

// .record {
// 	box-sizing: border-box;
// 	width: 926px;
// 	padding: 40px 32px;
// 	text-align: left;

// 	.record_top {
// 		display: flex;

// 		.record_top_left {
// 			img {
// 				width: 48px;
// 				height: 48px;
// 				border-radius: 50%;
// 			}
// 		}

// 		.record_top_right {
// 			margin-left: 16px;
// 			color: #333333;
// 			font-size: 16px;
// 			word-break: break-all;
// 			text-align: left;
// 		}
// 	}

// 	.record_body {
// 		margin-left: 64px;
// 	}

// 	.record_img {
// 		margin-top: 12px;
// 		display: flex;
// 		flex-wrap: wrap;

// 		.record_img_item {
// 			margin-right: 10px;
// 			margin-bottom: 10px;

// 			img {
// 				width: 180px;
// 				height: 180px;
// 			}
// 		}
// 	}

// 	.record_type {
// 		color: #666666;
// 		font-size: 14px;
// 		margin-bottom: 16px;
// 	}

// 	.reply {
// 		padding: 20px 16px;
// 		color: #333333;
// 		font-size: 14px;
// 		background: #F6F7F9;
// 		line-height: 19px;
// 		word-break: break-all;
// 	}
// }
</style>
